exports.components = {
  "component---src-components-global-single-page-blog-detail-js": () => import("./../../../src/components/global/single-page/blog-detail.js" /* webpackChunkName: "component---src-components-global-single-page-blog-detail-js" */),
  "component---src-components-global-single-page-ou-work-single-js": () => import("./../../../src/components/global/single-page/ou-work-single.js" /* webpackChunkName: "component---src-components-global-single-page-ou-work-single-js" */),
  "component---src-components-global-single-page-service-single-js": () => import("./../../../src/components/global/single-page/service-single.js" /* webpackChunkName: "component---src-components-global-single-page-service-single-js" */),
  "component---src-components-pages-with-pagination-blog-page-paginate-js": () => import("./../../../src/components/pages-with-pagination/blog-page-paginate.js" /* webpackChunkName: "component---src-components-pages-with-pagination-blog-page-paginate-js" */),
  "component---src-components-pages-with-pagination-testimonials-pagination-js": () => import("./../../../src/components/pages-with-pagination/testimonials-pagination.js" /* webpackChunkName: "component---src-components-pages-with-pagination-testimonials-pagination-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-crm-platter-js": () => import("./../../../src/pages/crm-platter.js" /* webpackChunkName: "component---src-pages-crm-platter-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

